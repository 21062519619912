import { GetProductsAPI } from "./api/getProducts"

let allProducts = [
  {
      _id: 1,
      title: 'Flash Drive - Vermont',
      image: 'images/usb_vermont.png',
      price: 10000,
      category: 'Usb',
      smallQty: 0,
      mediumQty: 0,
      largeQty: 0,
      xlQty: 0,
      xxlQty: 0,
      xxxlQty: 0,
      totalQty: 0,
      description: "<p>Material: 100% Cotton</p><p>Stand Collar Fit Type: Regular</p><p>Thickness: Standard</p><p>Color: White,Sky Blue,Black</p><p>Size: S,M,L,XL,2XL,3XL </p><p>Occasion: Casual, Fashion, Vintage</p><p>Season: Summer</p><p>Product Description: Mens Chinese Style 100% Cotton Solid Color Stand Collar Vintage Casual Summer T Shirts Package included: 1*T-Shirt Please Note:Please see the Size Reference to find the correct size.</p>"
  },
  {
      _id: 2,
      title: 'Flash Drive - Noomer',
      image: 'images/usb_noomer.png',
      price: 10000,
      category: 'Usb',
      smallQty: 0,
      mediumQty: 0,
      largeQty: 0,
      xlQty: 0,
      xxlQty: 0,
      xxxlQty: 0,
      totalQty: 60,
      description: "<p>Material: 100% Cotton</p><p>Stand Collar Fit Type: Regular</p><p>Thickness: Standard</p><p>Color: White,Sky Blue,Black</p><p>Size: S,M,L,XL,2XL,3XL </p><p>Occasion: Casual, Fashion, Vintage</p><p>Season: Summer</p><p>Product Description: Mens Chinese Style 100% Cotton Solid Color Stand Collar Vintage Casual Summer T Shirts Package included: 1*T-Shirt Please Note:Please see the Size Reference to find the correct size.</p>"
  },
  {
      _id: 3,
      title: 'Flash Drive - Dailai',
      image: 'images/usb_dailai.png',
      price: 13000,
      category: 'Usb',
      smallQty: 0,
      mediumQty: 0,
      largeQty: 0,
      xlQty: 0,
      xxlQty: 0,
      xxxlQty: 0,
      totalQty: 60,
      description: "<p>Material: 100% Cotton</p><p>Stand Collar Fit Type: Regular</p><p>Thickness: Standard</p><p>Color: White,Sky Blue,Black</p><p>Size: S,M,L,XL,2XL,3XL </p><p>Occasion: Casual, Fashion, Vintage</p><p>Season: Summer</p><p>Product Description: Mens Chinese Style 100% Cotton Solid Color Stand Collar Vintage Casual Summer T Shirts Package included: 1*T-Shirt Please Note:Please see the Size Reference to find the correct size.</p>"
  },
  {
      _id: 4,
      title: 'Flash Drive - Woodcoin',
      image: 'images/usb_woodcoin.png',
      price: 15000,
      category: 'Usb',
      smallQty: 0,
      mediumQty: 0,
      largeQty: 0,
      xlQty: 0,
      xxlQty: 0,
      xxxlQty: 0,
      totalQty: 60,
      description: "<p>Material: 100% Cotton</p><p>Stand Collar Fit Type: Regular</p><p>Thickness: Standard</p><p>Color: White,Sky Blue,Black</p><p>Size: S,M,L,XL,2XL,3XL </p><p>Occasion: Casual, Fashion, Vintage</p><p>Season: Summer</p><p>Product Description: Mens Chinese Style 100% Cotton Solid Color Stand Collar Vintage Casual Summer T Shirts Package included: 1*T-Shirt Please Note:Please see the Size Reference to find the correct size.</p>"
  },
  {
      _id: 5,
      title: 'Cap - Dailai',
      image: 'images/cap_dailai.png',
      price: 12500,
      category: 'Caps',
      smallQty: 0,
      mediumQty: 0,
      largeQty: 0,
      xlQty: 0,
      xxlQty: 0,
      xxxlQty: 0,
      totalQty: 60,
      description: "<p>Material: 100% Cotton</p><p>Stand Collar Fit Type: Regular</p><p>Thickness: Standard</p><p>Color: White,Sky Blue,Black</p><p>Size: S,M,L,XL,2XL,3XL </p><p>Occasion: Casual, Fashion, Vintage</p><p>Season: Summer</p><p>Product Description: Mens Chinese Style 100% Cotton Solid Color Stand Collar Vintage Casual Summer T Shirts Package included: 1*T-Shirt Please Note:Please see the Size Reference to find the correct size.</p>"
  },
  {
      _id: 6,
      title: 'Cap - Woodcoin',
      image: 'images/cap_woodcoin.png',
      price: 8500,
      category: 'Caps',
      smallQty: 0,
      mediumQty: 0,
      largeQty: 0,
      xlQty: 0,
      xxlQty: 0,
      xxxlQty: 0,
      totalQty: 60,
      description: "<p>Material: 100% Cotton</p><p>Stand Collar Fit Type: Regular</p><p>Thickness: Standard</p><p>Color: White,Sky Blue,Black</p><p>Size: S,M,L,XL,2XL,3XL </p><p>Occasion: Casual, Fashion, Vintage</p><p>Season: Summer</p><p>Product Description: Mens Chinese Style 100% Cotton Solid Color Stand Collar Vintage Casual Summer T Shirts Package included: 1*T-Shirt Please Note:Please see the Size Reference to find the correct size.</p>"
  },
  {
      _id: 7,
      title: 'Cap - Dailai',
      image: 'images/cap_dailai_side.png',
      price: 12000,
      category: 'Caps',
      smallQty: 0,
      mediumQty: 0,
      largeQty: 0,
      xlQty: 0,
      xxlQty: 0,
      xxxlQty: 0,
      totalQty: 60,
      description: "<p>Material: 100% Cotton</p><p>Stand Collar Fit Type: Regular</p><p>Thickness: Standard</p><p>Color: White,Sky Blue,Black</p><p>Size: S,M,L,XL,2XL,3XL </p><p>Occasion: Casual, Fashion, Vintage</p><p>Season: Summer</p><p>Product Description: Mens Chinese Style 100% Cotton Solid Color Stand Collar Vintage Casual Summer T Shirts Package included: 1*T-Shirt Please Note:Please see the Size Reference to find the correct size.</p>"
  },
  {
      _id: 8,
      title: 'Cap - Vermont',
      image: 'images/cap_vermont.png',
      price: 8000,
      category: 'Caps',
      smallQty: 0,
      mediumQty: 0,
      largeQty: 0,
      xlQty: 0,
      xxlQty: 0,
      xxxlQty: 0,
      totalQty: 60,
      description: "<p>Material: 100% Cotton</p><p>Stand Collar Fit Type: Regular</p><p>Thickness: Standard</p><p>Color: White,Sky Blue,Black</p><p>Size: S,M,L,XL,2XL,3XL </p><p>Occasion: Casual, Fashion, Vintage</p><p>Season: Summer</p><p>Product Description: Mens Chinese Style 100% Cotton Solid Color Stand Collar Vintage Casual Summer T Shirts Package included: 1*T-Shirt Please Note:Please see the Size Reference to find the correct size.</p>"
  },
  {
      _id: 9,
      title: 'Tshirt - Vermont white',
      image: 'images/tshirt_vermont_w.png',
      price: 4000,
      category: 'Tshirts',
      smallQty: 10,
      mediumQty: 10,
      largeQty: 10,
      xlQty: 10,
      xxlQty: 10,
      xxxlQty: 10,
      totalQty: 60,
      description: "<p>Material: 100% Cotton</p><p>Stand Collar Fit Type: Regular</p><p>Thickness: Standard</p><p>Color: White,Sky Blue,Black</p><p>Size: S,M,L,XL,2XL,3XL </p><p>Occasion: Casual, Fashion, Vintage</p><p>Season: Summer</p><p>Product Description: Mens Chinese Style 100% Cotton Solid Color Stand Collar Vintage Casual Summer T Shirts Package included: 1*T-Shirt Please Note:Please see the Size Reference to find the correct size.</p>"
  },
  {
      _id: 10,
      title: 'Tshirt - Noomer black',
      image: 'images/tshirt_noomer_b.png',
      price: 4000,
      category: 'Tshirts',
      smallQty: 10,
      mediumQty: 10,
      largeQty: 10,
      xlQty: 10,
      xxlQty: 10,
      xxxlQty: 10,
      totalQty: 60,
      description: "<p>Material: 100% Cotton</p><p>Stand Collar Fit Type: Regular</p><p>Thickness: Standard</p><p>Color: White,Sky Blue,Black</p><p>Size: S,M,L,XL,2XL,3XL </p><p>Occasion: Casual, Fashion, Vintage</p><p>Season: Summer</p><p>Product Description: Mens Chinese Style 100% Cotton Solid Color Stand Collar Vintage Casual Summer T Shirts Package included: 1*T-Shirt Please Note:Please see the Size Reference to find the correct size.</p>"
  },
  {
      _id: 11,
      title: 'Tshirt - Dailai black',
      image: 'images/tshirt_dailai_b.png',
      price: 4000,
      category: 'Tshirts',
      smallQty: 10,
      mediumQty: 10,
      largeQty: 10,
      xlQty: 10,
      xxlQty: 10,
      xxxlQty: 10,
      totalQty: 60,
      description: "<p>Material: 100% Cotton</p><p>Stand Collar Fit Type: Regular</p><p>Thickness: Standard</p><p>Color: White,Sky Blue,Black</p><p>Size: S,M,L,XL,2XL,3XL </p><p>Occasion: Casual, Fashion, Vintage</p><p>Season: Summer</p><p>Product Description: Mens Chinese Style 100% Cotton Solid Color Stand Collar Vintage Casual Summer T Shirts Package included: 1*T-Shirt Please Note:Please see the Size Reference to find the correct size.</p>"
  },
  {
      _id: 12,
      title: 'Tshirt - Woodcoin white',
      image: 'images/tshirt_woodcoin_w.png',
      price: 4000,
      category: 'Tshirts',
      smallQty: 10,
      mediumQty: 10,
      largeQty: 10,
      xlQty: 10,
      xxlQty: 10,
      xxxlQty: 10,
      totalQty: 60,
      description: "<p>Material: 100% Cotton</p><p>Stand Collar Fit Type: Regular</p><p>Thickness: Standard</p><p>Color: White,Sky Blue,Black</p><p>Size: S,M,L,XL,2XL,3XL </p><p>Occasion: Casual, Fashion, Vintage</p><p>Season: Summer</p><p>Product Description: Mens Chinese Style 100% Cotton Solid Color Stand Collar Vintage Casual Summer T Shirts Package included: 1*T-Shirt Please Note:Please see the Size Reference to find the correct size.</p>"
  },
  {
      _id: 13,
      title: 'Tshirt - Dailai white',
      image: 'images/tshirt_dailai_w.png',
      price: 4000,
      category: 'Tshirts',
      smallQty: 10,
      mediumQty: 10,
      largeQty: 10,
      xlQty: 10,
      xxlQty: 10,
      xxxlQty: 10,
      totalQty: 60,
      description: "<p>Material: 100% Cotton</p><p>Stand Collar Fit Type: Regular</p><p>Thickness: Standard</p><p>Color: White,Sky Blue,Black</p><p>Size: S,M,L,XL,2XL,3XL </p><p>Occasion: Casual, Fashion, Vintage</p><p>Season: Summer</p><p>Product Description: Mens Chinese Style 100% Cotton Solid Color Stand Collar Vintage Casual Summer T Shirts Package included: 1*T-Shirt Please Note:Please see the Size Reference to find the correct size.</p>"
  },
  {
      _id: 14,
      title: 'Tshirt - Noomer white',
      image: 'images/tshirt_noomer_w.png',
      price: 4000,
      category: 'Tshirts',
      smallQty: 10,
      mediumQty: 10,
      largeQty: 10,
      xlQty: 10,
      xxlQty: 10,
      xxxlQty: 10,
      totalQty: 60,
      description: "<p>Material: 100% Cotton</p><p>Stand Collar Fit Type: Regular</p><p>Thickness: Standard</p><p>Color: White,Sky Blue,Black</p><p>Size: S,M,L,XL,2XL,3XL </p><p>Occasion: Casual, Fashion, Vintage</p><p>Season: Summer</p><p>Product Description: Mens Chinese Style 100% Cotton Solid Color Stand Collar Vintage Casual Summer T Shirts Package included: 1*T-Shirt Please Note:Please see the Size Reference to find the correct size.</p>"
  },
  {
      _id: 15,
      title: 'Mug - Vermont',
      image: 'images/mug_vermont.png',
      price: 4000,
      category: 'Mugs',
      smallQty: 0,
      mediumQty: 0,
      largeQty: 0,
      xlQty: 0,
      xxlQty: 0,
      xxxlQty: 0,
      totalQty: 60,
      description: "<p>Material: 100% Cotton</p><p>Stand Collar Fit Type: Regular</p><p>Thickness: Standard</p><p>Color: White,Sky Blue,Black</p><p>Size: S,M,L,XL,2XL,3XL </p><p>Occasion: Casual, Fashion, Vintage</p><p>Season: Summer</p><p>Product Description: Mens Chinese Style 100% Cotton Solid Color Stand Collar Vintage Casual Summer T Shirts Package included: 1*T-Shirt Please Note:Please see the Size Reference to find the correct size.</p>"
  },
  {
      _id: 16,
      title: 'Mug - Dailai',
      image: 'images/mug_dailai.png',
      price: 4000,
      category: 'Mugs',
      smallQty: 0,
      mediumQty: 0,
      largeQty: 0,
      xlQty: 0,
      xxlQty: 0,
      xxxlQty: 0,
      totalQty: 60,
      description: "<p>Material: 100% Cotton</p><p>Stand Collar Fit Type: Regular</p><p>Thickness: Standard</p><p>Color: White,Sky Blue,Black</p><p>Size: S,M,L,XL,2XL,3XL </p><p>Occasion: Casual, Fashion, Vintage</p><p>Season: Summer</p><p>Product Description: Mens Chinese Style 100% Cotton Solid Color Stand Collar Vintage Casual Summer T Shirts Package included: 1*T-Shirt Please Note:Please see the Size Reference to find the correct size.</p>"
  },
  {
      _id: 17,
      title: 'Mug - Noomer',
      image: 'images/mug_noomer.png',
      price: 4000,
      category: 'Mugs',
      smallQty: 0,
      mediumQty: 0,
      largeQty: 0,
      xlQty: 0,
      xxlQty: 0,
      xxxlQty: 0,
      totalQty: 60,
      description: "<p>Material: 100% Cotton</p><p>Stand Collar Fit Type: Regular</p><p>Thickness: Standard</p><p>Color: White,Sky Blue,Black</p><p>Size: S,M,L,XL,2XL,3XL </p><p>Occasion: Casual, Fashion, Vintage</p><p>Season: Summer</p><p>Product Description: Mens Chinese Style 100% Cotton Solid Color Stand Collar Vintage Casual Summer T Shirts Package included: 1*T-Shirt Please Note:Please see the Size Reference to find the correct size.</p>"
  },
  {
      _id: 18,
      title: 'Mug - Woodcoin',
      image: 'images/mug_woodcoin.png',
      price: 4000,
      category: 'Mugs',
      smallQty: 0,
      mediumQty: 0,
      largeQty: 0,
      xlQty: 0,
      xxlQty: 0,
      xxxlQty: 0,
      totalQty: 60,
      description: "<p>Material: 100% Cotton</p><p>Stand Collar Fit Type: Regular</p><p>Thickness: Standard</p><p>Color: White,Sky Blue,Black</p><p>Size: S,M,L,XL,2XL,3XL </p><p>Occasion: Casual, Fashion, Vintage</p><p>Season: Summer</p><p>Product Description: Mens Chinese Style 100% Cotton Solid Color Stand Collar Vintage Casual Summer T Shirts Package included: 1*T-Shirt Please Note:Please see the Size Reference to find the correct size.</p>"
  },
  {
      _id: 19,
      title: 'Tumbler - Noomer',
      image: 'images/tumbler_noomer.png',
      price: 4000,
      category: 'Tumblers',
      smallQty: 0,
      mediumQty: 0,
      largeQty: 0,
      xlQty: 0,
      xxlQty: 0,
      xxxlQty: 0,
      totalQty: 60,
      description: "<p>Material: 100% Cotton</p><p>Stand Collar Fit Type: Regular</p><p>Thickness: Standard</p><p>Color: White,Sky Blue,Black</p><p>Size: S,M,L,XL,2XL,3XL </p><p>Occasion: Casual, Fashion, Vintage</p><p>Season: Summer</p><p>Product Description: Mens Chinese Style 100% Cotton Solid Color Stand Collar Vintage Casual Summer T Shirts Package included: 1*T-Shirt Please Note:Please see the Size Reference to find the correct size.</p>"
  },
  {
      _id: 20,
      title: 'Tumbler - Vermont',
      image: 'images/tumbler_vermont.png',
      price: 4000,
      category: 'Tumblers',
      smallQty: 0,
      mediumQty: 0,
      largeQty: 0,
      xlQty: 0,
      xxlQty: 0,
      xxxlQty: 0,
      totalQty: 60,
      description: "<p>Material: 100% Cotton</p><p>Stand Collar Fit Type: Regular</p><p>Thickness: Standard</p><p>Color: White,Sky Blue,Black</p><p>Size: S,M,L,XL,2XL,3XL </p><p>Occasion: Casual, Fashion, Vintage</p><p>Season: Summer</p><p>Product Description: Mens Chinese Style 100% Cotton Solid Color Stand Collar Vintage Casual Summer T Shirts Package included: 1*T-Shirt Please Note:Please see the Size Reference to find the correct size.</p>"
  },
  {
      _id: 21,
      title: 'Tumbler - Woodcoin',
      image: 'images/tumbler_woodcoin.png',
      price: 4000,
      category: 'Tumblers',
      smallQty: 0,
      mediumQty: 0,
      largeQty: 0,
      xlQty: 0,
      xxlQty: 0,
      xxxlQty: 0,
      totalQty: 60,
      description: "<p>Material: 100% Cotton</p><p>Stand Collar Fit Type: Regular</p><p>Thickness: Standard</p><p>Color: White,Sky Blue,Black</p><p>Size: S,M,L,XL,2XL,3XL </p><p>Occasion: Casual, Fashion, Vintage</p><p>Season: Summer</p><p>Product Description: Mens Chinese Style 100% Cotton Solid Color Stand Collar Vintage Casual Summer T Shirts Package included: 1*T-Shirt Please Note:Please see the Size Reference to find the correct size.</p>"
  },
  {
      _id: 22,
      title: 'Tumbler - Dailai',
      image: 'images/tumbler_dailai.png',
      price: 4000,
      category: 'Tumblers',
      smallQty: 0,
      mediumQty: 0,
      largeQty: 0,
      xlQty: 0,
      xxlQty: 0,
      xxxlQty: 0,
      totalQty: 60,
      description: "<p>Material: 100% Cotton</p><p>Stand Collar Fit Type: Regular</p><p>Thickness: Standard</p><p>Color: White,Sky Blue,Black</p><p>Size: S,M,L,XL,2XL,3XL </p><p>Occasion: Casual, Fashion, Vintage</p><p>Season: Summer</p><p>Product Description: Mens Chinese Style 100% Cotton Solid Color Stand Collar Vintage Casual Summer T Shirts Package included: 1*T-Shirt Please Note:Please see the Size Reference to find the correct size.</p>"
  },
  {
      _id: 23,
      title: 'Pillow - Vermont',
      image: 'images/pillow_vermont.png',
      price: 4000,
      category: 'Pillows',
      smallQty: 0,
      mediumQty: 0,
      largeQty: 0,
      xlQty: 0,
      xxlQty: 0,
      xxxlQty: 0,
      totalQty: 60,
      description: "<p>Material: 100% Cotton</p><p>Stand Collar Fit Type: Regular</p><p>Thickness: Standard</p><p>Color: White,Sky Blue,Black</p><p>Size: S,M,L,XL,2XL,3XL </p><p>Occasion: Casual, Fashion, Vintage</p><p>Season: Summer</p><p>Product Description: Mens Chinese Style 100% Cotton Solid Color Stand Collar Vintage Casual Summer T Shirts Package included: 1*T-Shirt Please Note:Please see the Size Reference to find the correct size.</p>"
  },
  {
      _id: 24,
      title: 'Pillow - Noomer',
      image: 'images/pillow_noomer.png',
      price: 4000,
      category: 'Pillows',
      smallQty: 0,
      mediumQty: 0,
      largeQty: 0,
      xlQty: 0,
      xxlQty: 0,
      xxxlQty: 0,
      totalQty: 60,
      description: "<p>Material: 100% Cotton</p><p>Stand Collar Fit Type: Regular</p><p>Thickness: Standard</p><p>Color: White,Sky Blue,Black</p><p>Size: S,M,L,XL,2XL,3XL </p><p>Occasion: Casual, Fashion, Vintage</p><p>Season: Summer</p><p>Product Description: Mens Chinese Style 100% Cotton Solid Color Stand Collar Vintage Casual Summer T Shirts Package included: 1*T-Shirt Please Note:Please see the Size Reference to find the correct size.</p>"
  },
  {
      _id: 25,
      title: 'Pillow - Dailai',
      image: 'images/pillow_dailai.png',
      price: 4000,
      category: 'Pillows',
      smallQty: 0,
      mediumQty: 0,
      largeQty: 0,
      xlQty: 0,
      xxlQty: 0,
      xxxlQty: 0,
      totalQty: 60,
      description: "<p>Material: 100% Cotton</p><p>Stand Collar Fit Type: Regular</p><p>Thickness: Standard</p><p>Color: White,Sky Blue,Black</p><p>Size: S,M,L,XL,2XL,3XL </p><p>Occasion: Casual, Fashion, Vintage</p><p>Season: Summer</p><p>Product Description: Mens Chinese Style 100% Cotton Solid Color Stand Collar Vintage Casual Summer T Shirts Package included: 1*T-Shirt Please Note:Please see the Size Reference to find the correct size.</p>"
  },
  {
      _id: 26,
      title: 'Pillow - Woodcoin',
      image: 'images/pillow_woodcoin.png',
      price: 4000,
      category: 'Pillows',
      smallQty: 0,
      mediumQty: 0,
      largeQty: 0,
      xlQty: 0,
      xxlQty: 0,
      xxxlQty: 0,
      totalQty: 60,
      description: "<p>Material: 100% Cotton</p><p>Stand Collar Fit Type: Regular</p><p>Thickness: Standard</p><p>Color: White,Sky Blue,Black</p><p>Size: S,M,L,XL,2XL,3XL </p><p>Occasion: Casual, Fashion, Vintage</p><p>Season: Summer</p><p>Product Description: Mens Chinese Style 100% Cotton Solid Color Stand Collar Vintage Casual Summer T Shirts Package included: 1*T-Shirt Please Note:Please see the Size Reference to find the correct size.</p>"
  },
  {
      _id: 27,
      title: 'Tote bag - Woodcoin',
      image: 'images/bag_woodcoin.png',
      price: 4000,
      category: 'Bags',
      smallQty: 0,
      mediumQty: 0,
      largeQty: 0,
      xlQty: 0,
      xxlQty: 0,
      xxxlQty: 0,
      totalQty: 60,
      description: "<p>Material: 100% Cotton</p><p>Stand Collar Fit Type: Regular</p><p>Thickness: Standard</p><p>Color: White,Sky Blue,Black</p><p>Size: S,M,L,XL,2XL,3XL </p><p>Occasion: Casual, Fashion, Vintage</p><p>Season: Summer</p><p>Product Description: Mens Chinese Style 100% Cotton Solid Color Stand Collar Vintage Casual Summer T Shirts Package included: 1*T-Shirt Please Note:Please see the Size Reference to find the correct size.</p>"
  },
  {
      _id: 28,
      title: 'Tote bag - Vermont',
      image: 'images/bag_vermont.png',
      price: 4000,
      category: 'Bags',
      smallQty: 0,
      mediumQty: 0,
      largeQty: 0,
      xlQty: 0,
      xxlQty: 0,
      xxxlQty: 0,
      totalQty: 60,
      description: "<p>Material: 100% Cotton</p><p>Stand Collar Fit Type: Regular</p><p>Thickness: Standard</p><p>Color: White,Sky Blue,Black</p><p>Size: S,M,L,XL,2XL,3XL </p><p>Occasion: Casual, Fashion, Vintage</p><p>Season: Summer</p><p>Product Description: Mens Chinese Style 100% Cotton Solid Color Stand Collar Vintage Casual Summer T Shirts Package included: 1*T-Shirt Please Note:Please see the Size Reference to find the correct size.</p>"
  },
  {
      _id: 29,
      title: 'Tote bag - Noomer',
      image: 'images/bag_noomer.png',
      price: 4000,
      category: 'Bags',
      smallQty: 0,
      mediumQty: 0,
      largeQty: 0,
      xlQty: 0,
      xxlQty: 0,
      xxxlQty: 0,
      totalQty: 5,
      description: "<p>Material: 100% Cotton</p><p>Stand Collar Fit Type: Regular</p><p>Thickness: Standard</p><p>Color: White,Sky Blue,Black</p><p>Size: S,M,L,XL,2XL,3XL </p><p>Occasion: Casual, Fashion, Vintage</p><p>Season: Summer</p><p>Product Description: Mens Chinese Style 100% Cotton Solid Color Stand Collar Vintage Casual Summer T Shirts Package included: 1*T-Shirt Please Note:Please see the Size Reference to find the correct size.</p>"
  }
]

// const getProducts = async() => {
//     let p = await GetProductsAPI()
//     console.log("p: ", p.status)
//     return p.data.message
// }

// const allProducts = GetProductsAPI()

export default allProducts
